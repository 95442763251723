import { Dictionary } from 'lodash'
import { isIndia } from 'utils/commonUtils'

import {
  AGENT_GROUP,
  AGENT_INCENTIVES,
  // AGENTS_PERFORMANCE,
  AUTO_INVENTORY_REPLENISHMENT,
  AUTOMATIC_PARTIAL_FULFILLMENT,
  BIDDING_INVITATION_LIST,
  BIDDING_REQUEST,
  BRAND_PORTAL,
  BUY_IN_INVOICE,
  CATEGORY_INSIGHTS,
  CLAIMS_MANAGEMENT,
  CONFIG_CM3_TARGET,
  CONSOLIDATED_PURCHASE_REQUEST,
  // COVERAGE_REPORT,
  CREDIT,
  CREDIT_DEBIT_NOTE,
  DELIVERY_MANAGEMENT,
  DELIVERY_MANAGEMENT_HUB,
  // DELIVERY_PERFORMANCE,
  DISTRIBUTOR_CENTER,
  DISTRIBUTOR_CONTRACT_FEE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_MANAGEMENT,
  DISTRIBUTOR_MARGIN,
  DISTRIBUTOR_PROFILE,
  DISTRIBUTOR_PURCHASE_ORDER,
  DISTRIBUTOR_PURCHASE_REQUEST,
  DISTRIBUTOR_REGISTER,
  FAILED_DELIVERY_RECEIVE,
  FINANCE_TASK_LIST,
  FULFILLMENTS,
  GOODS_NOT_FOCUS,
  HOME,
  INBOUND_LIST,
  INCENTIVES_CONFIG,
  INVENTORY_ADJUSTMENT,
  INVENTORY_LIST,
  INVENTORY_RECOMMEND,
  INVENTORY_REPORT,
  // INVENTORY_REPORT,
  MARKETING,
  MARKETING_BUDGET,
  MASTER_DATA,
  MISSION_PERFORMANCE,
  ORDER_LIST,
  OUTLET_MANAGEMENT,
  // OUTLETS_PERFORMANCE,
  PACKAGE_LIST,
  PARTNER,
  PAYMENT_REQUEST,
  PAYOUT_STATEMENT,
  PICKLIST_ASSIGNMENT,
  PICKLIST_CHECKOUT,
  PRE_ORDERS,
  PRICE_MANAGEMENT,
  PRICE_TYPE_CONFIG,
  PRODUCT_AVAILABLE,
  PRODUCT_LIST,
  PRODUCT_LISTING,
  PRODUCT_PRE_ORDER,
  PRODUCT_TRADING_BY_LISTING,
  PURCHASE_ORDER_LIST,
  PURCHASING,
  REASON_NEED_ADJUST,
  REMITTANCE_LIST,
  REPORT,
  REPORT_CLAIMS,
  REPORT_DISTRIBUTOR_RECONCILIATION,
  REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
  REPORT_GST_PURCHASES,
  REPORT_GST_SALES,
  REPORT_GST_SALES_HUB,
  REPORT_INVENTORY,
  REPORT_RIDER,
  REPORT_SALES_SUMMARY,
  REPORT_SUMMARY,
  RETAIL_PURCHASE_REQUEST_LIST,
  RETURN_ORDER_LIST,
  RIDER,
  RIDER_MANAGEMENT,
  SALES_PERFORMANCE,
  SELL_OUT_INVOICE,
  STOCK_TRANSFER,
  SUBSIDY_RULE,
  SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
  SUPPLIER_LIST,
  SUPPLIER_LIST_VN,
  SUPPLIER_PORTAL,
  SUPPLIER_PRODUCT_LIST,
  TASK_LIST,
  TAX_MANAGEMENT,
  TRADE_ORDERS,
  TRANSPORTATION_VENDOR,
  VEHICLE_MANAGEMENT,
  VIGO_DELIVERY,
  VIGO_MASTER_QUOTATION,
  VIGO_PURCHASE_ORDER,
  WAREHOUSE_LIST,
  WAREHOUSE_WMS_LIST,
  WMS,
} from './routes'

export const ADMIN_ROLE = 'Administrator'
export const EXTERNAL_DISTRIBUTOR = 'External Distributor'
export const EXTERNAL_SUPPLIER = 'External Supplier'
export const BGM = 'Business Growth Manager'
export const BRAND_MANAGER = 'Brand manager'
export const MARKETING_ROLE = 'Marketing'
export const PO_MANAGER = 'PO Manager'
export const FINANCE = 'Finance'
export const CONTENT = 'Content'
export const RIDER_ROLE = 'Rider'
export const GUEST = 'Guest'
export const SOURCING_MANAGER = 'Sourcing Manager'
export const LOGISTICS_MANAGER = 'Logistics manager'
export const MASTER_DISTRIBUTOR_HUB = 'Master Distributor Hub'
export const EXTERNAL_DISTRIBUTOR_GROUP = 'External Distributor Group'
export const ADMIN_MARKETPLACE = 'Administrator Marketplace'

const ACL: Dictionary<string[]> = {
  [ADMIN_ROLE]: ['*'], // all access
  [BRAND_MANAGER]: [
    BRAND_PORTAL,
    SALES_PERFORMANCE,
    CATEGORY_INSIGHTS,
    // DELIVERY_PERFORMANCE,
    INVENTORY_REPORT,
    // AGENTS_PERFORMANCE,
    // OUTLETS_PERFORMANCE,
    // COVERAGE_REPORT,
    PRICE_MANAGEMENT,
    MISSION_PERFORMANCE,
    TRADE_ORDERS,
    PRODUCT_AVAILABLE,
  ],
  [EXTERNAL_DISTRIBUTOR]: [
    PRODUCT_TRADING_BY_LISTING,
    PRODUCT_AVAILABLE,
    WMS,
    AUTO_INVENTORY_REPLENISHMENT,
    INBOUND_LIST,
    WAREHOUSE_WMS_LIST,
    TASK_LIST,
    FULFILLMENTS,
    ORDER_LIST,
    PRE_ORDERS,
    PICKLIST_ASSIGNMENT,
    PICKLIST_CHECKOUT,
    RIDER,
    RIDER_MANAGEMENT,
    PACKAGE_LIST,
    DELIVERY_MANAGEMENT,
    FAILED_DELIVERY_RECEIVE,
    VEHICLE_MANAGEMENT,
    REMITTANCE_LIST,
    DISTRIBUTOR_MANAGEMENT,
    DISTRIBUTOR_PROFILE,
    REPORT,
    REPORT_RIDER,
    REPORT_DISTRIBUTOR_RECONCILIATION,
    REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
    MASTER_DATA,
    PRODUCT_LISTING,
    BUY_IN_INVOICE,
    SELL_OUT_INVOICE,
    MARKETING,
    SUBSIDY_RULE,
    DISTRIBUTOR_CENTER,
    PURCHASING,
    CREDIT,
    INVENTORY_LIST,
    RETURN_ORDER_LIST,
    CREDIT_DEBIT_NOTE,
    MARKETING_BUDGET,
    STOCK_TRANSFER,
  ],
  [EXTERNAL_SUPPLIER]: [
    PURCHASE_ORDER_LIST,
    SUPPLIER_PORTAL,
    SUPPLIER_PRODUCT_LIST,
    BIDDING_INVITATION_LIST,
    PRODUCT_AVAILABLE,
  ],
  [BGM]: [
    // Master Data
    DISTRIBUTOR_LIST,
    PRODUCT_LIST,
    SUPPLIER_LIST,
    SUPPLIER_PRODUCT_LIST,
    WAREHOUSE_LIST,
    WAREHOUSE_WMS_LIST,
    PRODUCT_LISTING,
    PRODUCT_TRADING_BY_LISTING,
    PRODUCT_AVAILABLE,
    // Supplier Portal
    SUPPLIER_PORTAL,
    RETAIL_PURCHASE_REQUEST_LIST,
    PURCHASE_ORDER_LIST,
    TASK_LIST,
    BIDDING_REQUEST,
    WMS,
    AUTO_INVENTORY_REPLENISHMENT,
    INBOUND_LIST,
    FULFILLMENTS,
    ORDER_LIST,
    PRE_ORDERS,
    TRADE_ORDERS,
    PICKLIST_ASSIGNMENT,
    PICKLIST_CHECKOUT,
    MARKETING,
    SUBSIDY_RULE,
    PURCHASING,
    MARKETING_BUDGET,
    DISTRIBUTOR_MANAGEMENT,
    DISTRIBUTOR_PROFILE,
    STOCK_TRANSFER,
    PARTNER,
    SUPPLIER_LIST_VN,
    INVENTORY_LIST,
  ],
  [PO_MANAGER]: [
    INBOUND_LIST,
    PURCHASE_ORDER_LIST,
    SUPPLIER_PORTAL,
    BIDDING_REQUEST,
    TASK_LIST,
    WMS,
    DISTRIBUTOR_PURCHASE_REQUEST,
    CONSOLIDATED_PURCHASE_REQUEST,
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    PRODUCT_AVAILABLE,
  ],
  [FINANCE]: [
    MARKETING_BUDGET,
    SUPPLIER_PORTAL,
    PURCHASE_ORDER_LIST,
    PRODUCT_TRADING_BY_LISTING,
    WMS,
    INBOUND_LIST,
    INVENTORY_LIST,
    BUY_IN_INVOICE,
    SELL_OUT_INVOICE,
    PAYMENT_REQUEST,
    TASK_LIST,
    PACKAGE_LIST,
    PRODUCT_LISTING,
    MARKETING,
    SUBSIDY_RULE,
    TAX_MANAGEMENT,
    SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
    DISTRIBUTOR_CONTRACT_FEE,
    TRADE_ORDERS,
    // Logistics
    TRANSPORTATION_VENDOR,
    VIGO_DELIVERY,
    PAYOUT_STATEMENT,
    FINANCE_TASK_LIST,
    VIGO_PURCHASE_ORDER,
    REASON_NEED_ADJUST,
    PRICE_TYPE_CONFIG,
  ],
  [CONTENT]: [
    SUPPLIER_PORTAL,
    PURCHASE_ORDER_LIST,
    WMS,
    INBOUND_LIST,
    INVENTORY_LIST,
  ],
  [RIDER_ROLE]: [HOME],
  [GUEST]: [HOME],
  [SOURCING_MANAGER]: [
    // Master Data
    PRODUCT_LIST,
    SUPPLIER_LIST,
    PRODUCT_PRE_ORDER,
    PRODUCT_TRADING_BY_LISTING,
    INVENTORY_RECOMMEND,
    PRODUCT_LISTING,
    PRODUCT_AVAILABLE,
    // Supplier Portal
    PURCHASE_ORDER_LIST,
    BIDDING_REQUEST,
    BIDDING_INVITATION_LIST,
    //PROCUREMENT,
    DISTRIBUTOR_PURCHASE_REQUEST,
    CONSOLIDATED_PURCHASE_REQUEST,
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    GOODS_NOT_FOCUS,
    AUTOMATIC_PARTIAL_FULFILLMENT,
    CONFIG_CM3_TARGET,
    // Logistics
    TRANSPORTATION_VENDOR,
    TRADE_ORDERS,
    //marketing
    MARKETING,
    SUBSIDY_RULE,
    PARTNER,
    SUPPLIER_LIST_VN,
    TASK_LIST,
  ],
  [LOGISTICS_MANAGER]: [
    // Master Data
    PRODUCT_LIST,
    SUPPLIER_LIST,
    WAREHOUSE_LIST,
    DISTRIBUTOR_LIST,
    DISTRIBUTOR_MANAGEMENT,
    DISTRIBUTOR_PROFILE,
    // Supplier Portal
    PURCHASE_ORDER_LIST,
    //procurement
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    // Logistics
    TRANSPORTATION_VENDOR,
    VIGO_DELIVERY,
    // Settings
    TASK_LIST,
    PARTNER,
    SUPPLIER_LIST_VN,
  ],
  [MASTER_DISTRIBUTOR_HUB]: [
    // Master Data
    SUPPLIER_PRODUCT_LIST,
    // Supplier Portal
    PURCHASE_ORDER_LIST,
    // WMS
    INBOUND_LIST,
    // Return
    RETURN_ORDER_LIST,
    CREDIT_DEBIT_NOTE,
  ],
  [MARKETING_ROLE]: [
    PAYOUT_STATEMENT,
    AGENT_INCENTIVES,
    INCENTIVES_CONFIG,
    AGENT_GROUP,
    TRADE_ORDERS,
    ORDER_LIST,
  ],
  [ADMIN_MARKETPLACE]: [
    INVENTORY_LIST,
    INBOUND_LIST,
    FULFILLMENTS,
    TRADE_ORDERS,
    ORDER_LIST,
    PICKLIST_ASSIGNMENT,
    RIDER,
    RIDER_MANAGEMENT,
    VEHICLE_MANAGEMENT,
    DELIVERY_MANAGEMENT,
    PACKAGE_LIST,
    DISTRIBUTOR_MANAGEMENT,
    DISTRIBUTOR_PROFILE,
    DISTRIBUTOR_REGISTER,
    PRODUCT_AVAILABLE,
    PRODUCT_LISTING,
  ],
}
const ACL_INDIA: Dictionary<string[]> = {
  [ADMIN_ROLE]: ['*'], // all access
  [EXTERNAL_DISTRIBUTOR]: [
    INVENTORY_ADJUSTMENT,
    PURCHASE_ORDER_LIST,
    RETAIL_PURCHASE_REQUEST_LIST,
    RETURN_ORDER_LIST,
    CREDIT_DEBIT_NOTE,
    PRE_ORDERS,
    WMS,
    INBOUND_LIST,
    FULFILLMENTS,
    ORDER_LIST,
    PICKLIST_ASSIGNMENT,
    PICKLIST_CHECKOUT,
    RIDER,
    RIDER_MANAGEMENT,
    PACKAGE_LIST,
    DELIVERY_MANAGEMENT,
    FAILED_DELIVERY_RECEIVE,
    VEHICLE_MANAGEMENT,
    REMITTANCE_LIST,
    REPORT,
    REPORT_RIDER,
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_SALES_SUMMARY,
    BUY_IN_INVOICE,
    SELL_OUT_INVOICE,
    DISTRIBUTOR_CENTER,
    PURCHASING,
    CREDIT,
    INVENTORY_LIST,
    CLAIMS_MANAGEMENT,
    `${OUTLET_MANAGEMENT}/list`,
  ],

  [EXTERNAL_SUPPLIER]: [
    PURCHASE_ORDER_LIST,
    SUPPLIER_PORTAL,
    SUPPLIER_PRODUCT_LIST,
    BIDDING_INVITATION_LIST,
    RETURN_ORDER_LIST,
  ],

  [BGM]: [
    INVENTORY_ADJUSTMENT,
    RETAIL_PURCHASE_REQUEST_LIST,
    RETURN_ORDER_LIST,
    // Master Data
    DISTRIBUTOR_LIST,
    PRODUCT_LIST,
    SUPPLIER_LIST,
    SUPPLIER_PRODUCT_LIST,
    WAREHOUSE_LIST,
    PRODUCT_LISTING,
    PAYOUT_STATEMENT,
    FINANCE_TASK_LIST,
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    // Supplier Portal
    PRE_ORDERS,
    TRADE_ORDERS,
    FULFILLMENTS,
    ORDER_LIST,
    PICKLIST_ASSIGNMENT,
    PICKLIST_CHECKOUT,
    SUPPLIER_PORTAL,
    PURCHASE_ORDER_LIST,
    BIDDING_REQUEST,
    WMS,
    MARKETING_BUDGET,
    INBOUND_LIST,
    INVENTORY_LIST,
    FULFILLMENTS,
    MARKETING,
    `${OUTLET_MANAGEMENT}/list`,
    INVENTORY_LIST,
  ],
  [PO_MANAGER]: [
    RETAIL_PURCHASE_REQUEST_LIST,
    INBOUND_LIST,
    PURCHASE_ORDER_LIST,
    SUPPLIER_PORTAL,
    BIDDING_REQUEST,
    WMS,
    //PROCUREMENT,
    DISTRIBUTOR_PURCHASE_REQUEST,
    CONSOLIDATED_PURCHASE_REQUEST,
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_RIDER,
    INVENTORY_LIST,
    DELIVERY_MANAGEMENT_HUB,
    PRE_ORDERS,
    RETURN_ORDER_LIST,
    CREDIT_DEBIT_NOTE,
  ],
  [FINANCE]: [
    INVENTORY_ADJUSTMENT,
    RETAIL_PURCHASE_REQUEST_LIST,
    DELIVERY_MANAGEMENT_HUB,
    SUPPLIER_PORTAL,
    PURCHASE_ORDER_LIST,
    WMS,
    INBOUND_LIST,
    INVENTORY_LIST,
    BUY_IN_INVOICE,
    SELL_OUT_INVOICE,
    PAYMENT_REQUEST,
    PACKAGE_LIST,
    MARKETING,
    // Logistics
    TRANSPORTATION_VENDOR,
    VIGO_DELIVERY,
    PAYOUT_STATEMENT,
    FINANCE_TASK_LIST,
    CLAIMS_MANAGEMENT,
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_RIDER,
    RETURN_ORDER_LIST,
    DISTRIBUTOR_MARGIN,
    TRADE_ORDERS,
  ],
  [CONTENT]: [WMS, INBOUND_LIST, INVENTORY_LIST],
  [RIDER_ROLE]: [HOME, DELIVERY_MANAGEMENT],
  [GUEST]: [HOME],
  [SOURCING_MANAGER]: [
    SUPPLIER_PRODUCT_LIST,
    INBOUND_LIST,
    INVENTORY_LIST,
    // Master Data
    PRODUCT_LIST,
    SUPPLIER_LIST,
    // Supplier Portal
    PURCHASE_ORDER_LIST,
    BIDDING_REQUEST,
    BIDDING_INVITATION_LIST,
    //PROCUREMENT,
    DISTRIBUTOR_PURCHASE_REQUEST,
    CONSOLIDATED_PURCHASE_REQUEST,
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    // Logistics
    TRANSPORTATION_VENDOR,
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_RIDER,
    RETURN_ORDER_LIST,
    CREDIT_DEBIT_NOTE,
    TASK_LIST,
    RETAIL_PURCHASE_REQUEST_LIST,
  ],
  [LOGISTICS_MANAGER]: [
    //procurement
    VIGO_MASTER_QUOTATION,
    DISTRIBUTOR_PURCHASE_ORDER,
    VIGO_PURCHASE_ORDER,
    RIDER_MANAGEMENT,
    VEHICLE_MANAGEMENT,
    DELIVERY_MANAGEMENT,
    // Logistics
    TRANSPORTATION_VENDOR,
    VIGO_DELIVERY,
    // Settings
    REPORT_CLAIMS,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_RIDER,
  ],
  [MASTER_DISTRIBUTOR_HUB]: [
    // Master Data
    SUPPLIER_PRODUCT_LIST,
    // Supplier Portal
    PURCHASE_ORDER_LIST,
    // WMS
    INBOUND_LIST,
    INVENTORY_LIST,
    // Return
    RETURN_ORDER_LIST,
    REPORT_GST_PURCHASES,
    REPORT_GST_SALES_HUB,
    REPORT_INVENTORY,
    REPORT_SUMMARY,
    REPORT_RIDER,
    TASK_LIST,
  ],
  [MARKETING_ROLE]: [
    PAYOUT_STATEMENT,
    AGENT_INCENTIVES,
    INCENTIVES_CONFIG,
    AGENT_GROUP,
    TRADE_ORDERS,
  ],
}

export const ROLES = Object.keys(isIndia ? ACL_INDIA : ACL).sort()

export const isAdmin = (roles: string[]) => roles.includes(ADMIN_ROLE)
export const isPOManager = (roles: string[]) => roles.includes(PO_MANAGER)
export const isFinance = (roles: string[]) => roles.includes(FINANCE)
export const isBGM = (roles: string[]) => roles.includes(BGM)
export const isAdminMarketplace = (roles: string[]) =>
  !isIndia && roles.includes(ADMIN_MARKETPLACE)

export const isDistributor = (roles: string[]) =>
  roles.includes(EXTERNAL_DISTRIBUTOR)
export const isSupplier = (roles: string[]) => roles.includes(EXTERNAL_SUPPLIER)

export const getRedirectRoutes = (
  aclRoutes: string[],
  currentRoute: string
): string[] => {
  return aclRoutes.reduce((prev, route) => {
    if (route.includes(currentRoute) && route !== currentRoute) {
      return [...prev, route]
    }

    return prev
  }, [])
}

export default isIndia ? ACL_INDIA : ACL
